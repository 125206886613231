import { graphql } from "gatsby"
import React from "react"
import {
  PageContainer,
  PageMain,
  PageSidebar,
  PageFooter,
  PageOuterSidebar,
} from "../assemblies/pageTemplate"
import { PageProps, ClusterContext } from "../types"
import { Stack } from "../layout/stack"
import { MarkdownCard } from "../assemblies/card"
import { PageHeader, PageTitle, PageMeta } from "../assemblies/pageHeader"
import { GUTTER } from "../layout/util"
import { Grid } from "../layout/grid"
import styled from "styled-components"
import { Box } from "../layout/box"

const ClusterGridWrapper = styled.div`
  overflow: hidden;
  margin-top: var(--s0);
  margin-bottom: var(--s0);
`

const ClusterGrid = styled(Grid)`
  grid-gap: 0;
  margin: calc(var(--scale-vline) / -2);
`

const ClusterGridItem = styled(Box).attrs(p => ({
  ...p,
  p: GUTTER,
}))`
  border-bottom: calc(var(--scale-vline) / 2) dashed var(--color-gray-light);
  border-right: calc(var(--scale-vline) / 2) dashed var(--color-gray-light);
`

const ClusterTemplate: React.FC<PageProps<
  GatsbyTypes.ClusterPageQuery,
  ClusterContext
>> = ({
  data: { markdownRemark, allMarkdownRemark },
  pageContext: { seriesInfo },
}) => (
  <PageContainer>
    <PageOuterSidebar>
      <PageSidebar />
      <PageMain>
        <PageHeader>
          <PageTitle
            title={markdownRemark?.frontmatter?.title || "Article Cluster"}
          />
          <PageMeta
            description={
              markdownRemark?.excerpt || "Article Cluster Description"
            }
          />
        </PageHeader>
        <Stack
          as="article"
          gap={0}
          dangerouslySetInnerHTML={{
            __html: markdownRemark?.html || "Article Cluster Body",
          }}
        />
      </PageMain>
    </PageOuterSidebar>
    {allMarkdownRemark.edges.length > 0 ? (
      <ClusterGridWrapper>
        <ClusterGrid>
          {allMarkdownRemark.edges.map(({ node }) => (
            <ClusterGridItem key={node.id}>
              <MarkdownCard
                remark={node}
                seriesInfo={
                  node.frontmatter?.path
                    ? seriesInfo[node.frontmatter.path]
                    : undefined
                }
              />
            </ClusterGridItem>
          ))}
        </ClusterGrid>
      </ClusterGridWrapper>
    ) : null}
    <PageFooter />
  </PageContainer>
)

export default ClusterTemplate

export const query = graphql`
  query ClusterPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...MarkdownNode
      html
    }
    allMarkdownRemark(
      filter: { frontmatter: { parent: { eq: $path } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...MarkdownNode
        }
      }
    }
  }
`
