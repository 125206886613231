import styled from "styled-components"
import { Scale, scale, PaddingProps } from "./util"
import React, { useRef, useEffect, useState } from "react"
import { ResizeObserver } from "resize-observer"
import { padStyle } from "./box"

interface GridProps extends PaddingProps {
  gap?: Scale
  minWidth?: string
}

const GridStyle = styled.div<GridProps & { isWide: boolean }>`
  display: grid;
  ${p => (p.gap ? `grid-gap: ${scale(p.gap)}` : "")}
  grid-template-columns: 100%;
  ${padStyle}

  ${p =>
    p.isWide
      ? `grid-template-columns: repeat(auto-fill, minmax(${p.minWidth}, 1fr));`
      : ""}

  @supports (width: min(${p => p.minWidth}, 100%)) {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(${p => p.minWidth}, 100%), 1fr)
    );
  }
`

export const Grid: React.FC<GridProps> = ({ minWidth = "20rem", ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isWide, setIsWide] = useState(false)
  useEffect(() => {
    if (!ref.current || CSS.supports("width", `min(${minWidth}, 100%)`)) {
      return
    }
    const gridNode = ref.current
    const test = document.createElement("div")
    test.style.width = minWidth
    gridNode.appendChild(test)
    const minToPixels = test.offsetWidth
    gridNode.removeChild(test)

    const ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        // Get the element's current dimensions
        const cr = entry.contentRect
        // `true` if the container is wider than the minimum
        setIsWide(cr.width > minToPixels)
      }
    })
    ro.observe(gridNode)
    return () => {
      ro.disconnect()
    }
  }, [ref.current, minWidth])

  return <GridStyle ref={ref} minWidth={minWidth} isWide={isWide} {...props} />
}
